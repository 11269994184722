module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-cloudflare-web-analytics/gatsby-browser.js'),
      options: {"plugins":[],"token":"250e66ce97fe47888a83ffc7595ba437"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
